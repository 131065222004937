import styled from "styled-components"
import arrowRight from "../../images/right.png"
import arrowLeft from "../../images/left.png"

export const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -4rem;
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
  video {
    object-fit: cover;
  }
  .swiper-wrapper {
    align-items: center;
    padding-bottom: 3.5rem;
    .swiper-slide {
      margin-bottom:2rem;
      @media screen and (max-width: 1400px) {
        display: grid;
        align-self: center;
        height: initial;
      }
    }
  }

  .desktop-only {
    .image{
      width: 50%;
    }
    .video {
      width: 100%;
    }
    video {
      height: calc(100vh - 200px);
      width: 100%;
    }
  }
  .swiper-container {
    height: calc(100vh - 160px);
    img {
      max-height: 78vh;
    }
    .swiper-pagination-fraction {
        text-align: left;
        font-size: 1rem;
        color: black;
        bottom: 0rem;
        left: 0rem;
    }
  }
  .swiper-pagination-fraction{
    text-align: left;
    font-size: 1.5rem;
    color: black;
  }
`

export const ProjectImages = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: start;
  align-content: center;
  text-align: center;
  column-gap: 4rem;
  row-gap: 1rem;
  margin: 2rem auto 0;
  min-height: calc(100vh - 180px);
  max-width: 100vw;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: 1100px) {
    max-width: 80vw;
    margin: 1rem auto 2rem;
  }
  @media screen and (min-width: 1400px) {
    max-width: 70vw;
  }

  @media screen and (min-width: 1500px) {
    max-width: 60vw;
  }

  @media screen and (min-width: 1800px) {
    max-width: 50vw;
  }
 
  div {
    text-align: left;
    display: grid;
    align-content: center;
    &:nth-child(1) {
      text-align: right;
    }
  }
  img {
    height: 100%;
    width: auto;
  }
`
const HoverArea = styled.button`
  position: fixed;
  height: 70vh;
  width: 50vw;
  top: 180px;
  background-color: transparent;
  border: 0;
  z-index: 1;
  color: transparent;
  &:focus {
    outline: 0;
    border: 0;
    opacity: 0.8;
  }
`
export const Prev = styled(HoverArea)`
  left: 0;
  cursor: url(${arrowLeft}), auto;
`

export const Next = styled(HoverArea)`
  right: 0;
  cursor: url(${arrowRight}), auto;
`

export const HeaderThree = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0;
  margin-top: 2.5rem;
  letter-spacing: 0;
  font-weight: 500;
  text-transform: lowercase;
  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
    margin-top: 1rem;
  }
`
export const BottomRight = styled.div`
  position: initial;
  bottom: 2.5rem;
  left: 3rem;
  @media screen and (min-width: 768px) {
    position: fixed;
    top: 2.5rem;
    right: 0;
    color: black; 
  }
`

export const ProjectDetails = styled.div`
  text-align: right;
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
  top: -1.5rem;
  @media screen and (max-width: 768px) {
    text-align: left;
  }
`

export const Small = styled.small`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  
`
export const CenterText = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;

    color: black;
  @media screen and (min-width: 768px) {
    text-align: center;
    margin: 0 auto;
  }
`


export const ProjectNumbers = styled.div`
  display: flex;
  max-width: 100vw;
  margin: 0 auto;
  @media screen and (min-width: 1400px) {
    max-width: 60vw;
  }

`
export const PMobileImages = styled.div`
  margin-bottom: 1rem;
  img,video {
    width: 100%;
    transition: 250ms ease;
  }
  .loading {
    display: none;
  }
  .loaded-thumb {
   opacity: 0;
   visibility: hidden;
   height: 0;
  }
`;