import React, { useState } from 'react'
import Img from "gatsby-image"
import { useInView } from 'react-intersection-observer';

interface Image  {
    file: {
        contentType: String,
        url: String,
    },
    fluid: Object,
    sizes: {
        aspectRatio: Number
    }
}

type PContentProps = {
    image: Image,
    mobile: Boolean
};
type PImageProps = {
    image: Image,
    mobile: Boolean
};

const ProjectImage = ({image, mobile}: PImageProps) => {
    const objectFitSize = image.sizes.aspectRatio >= 1  ? "cover" : "contain";
    // const styles = image.sizes.aspectRatio <= 1 && mobile ? "initial" : "initial";

    const [loaded, setLoaded]= useState(false);
    const { ref, inView, entry } = useInView({
        threshold: 0,
        triggerOnce: true
    });
    
    return( 
        <div>  
            {mobile ?
                <div ref={ref}>
                    {inView &&
                        <>
                        <img 
                            className={loaded ? "loaded" : "loading"}
                            onLoad={(e)=> setLoaded(true)}
                            sizes={image.fluid.sizes} 
                            alt={image.title}
                            src={image.fixed.src} 
                            srcSet={image.fluid.srcSet}
                        />
                        <img 
                            className={loaded ? "loaded-thumb" : "loading-thumb"}
                            alt={image.title}
                            src={image.fixed.src} 
                        />
                        </>
                    }   
                </div>
            : 
            <Img 
                fluid={image.fluid} 
                fadeIn={false}
                alt={image.title}
                imgStyle={{ 
                    objectFit: objectFitSize
                }}
            />
}
        </div>
    )
}

const ProjectContent = ({image, mobile,videoThumbnail}: PContentProps) => {

    if (image.file.contentType.includes("image")) {
            return <ProjectImage mobile={mobile} image={image} />
    } else if (image.file.contentType.includes('video')) {
        return  (
            <div className="video">
                    <video poster={videoThumbnail ? `${videoThumbnail.file.url}?w=1000` : ''} width="320" height="240" controls playsInline>
                        <source src={`${image.file.url}#t=0`} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
            )
    }
    return null;
}

export default ProjectContent