import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import { HeaderColors } from '../../layouts/styles';
import  ProjectContent  from './components/ProjectContent'
import {
    ProjectImages, 
    Next, Prev, 
    HeaderThree, 
    ProjectDetails, 
    CenterText,
    Small,
    ProjectWrapper,
    BottomRight,
    ProjectNumbers,
    PMobileImages
} from './styles.js'
import debounce from '../../utils/utils.js';
import { uuid as key}  from 'uuidv4';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Mousewheel } from 'swiper';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,Mousewheel]);

// Import Swiper styles
import 'swiper/swiper-bundle.css';

type Node = {
    tags: Array<string>;
    images: Array<any>;
    title: String
}

interface Props {
    pageContext: {
        slug: {
            title: string
        }
    },
    data: {
        allContentfulPost: {
            edges: Array<Node>
        }
    }
}

const Project = ({ data }: Props) => {
    data = data.allContentfulPost.edges[0].node;
    const {  title, images, tags, videoThumbnail } = data;
    console.log(data)
    return (
        <ProjectWrapper>
            <div>
                {images && 
                    <>
                        <ProjectDetails>
                            <HeaderThree>
                                Project &mdash; {title}
                            </HeaderThree>
                        </ProjectDetails>
                        <div className="desktop-only">
                            <Swiper
                                spaceBetween={20}
                                slidesPerView={tags && tags.indexOf("video") !== -1 ? 1 : 2}
                                mousewheel={false}
                                pagination={{type: "fraction", clickable: true }}
                                grabCursor={tags && tags.indexOf("video") !== -1 ? false : true }
                                loop={tags && tags.indexOf("video") !== -1 ? false : true }
                                centeredSlides={false}
                            >
                                {images && images.map((image: Object,i: String) => {
                                    const props = {
                                        image: image,
                                        i: i,
                                        mobile: false,
                                        videoThumbnail
                                    }
                                   
                                    return <SwiperSlide className={image.file.contentType.includes("image") ? "image" :"video"} key={key()}>
                                        <ProjectContent {...props}  key={key()} />
                                    </SwiperSlide>
                                })} 
                            </Swiper>
                        </div>
                        <div className="mobile-only">
                            {images && images.map((image: Object,i: String) => {
                                const props = {
                                    image: image,
                                    i: i,
                                    mobile: true,
                                    videoThumbnail
                                }
                                return <PMobileImages key={key()}><ProjectContent {...props}  key={key()} /></PMobileImages>
                            })
                        }
                        </div>
                    </>
                }
            </div>
        </ProjectWrapper>
    )
}
   
export const query = graphql`
    query ProjectQuery($slug: String!) {
        allContentfulPost(filter: { title: { eq: $slug }}  ) {
            edges {
                node {
                    title
                    tags
                    images {
                        title
                        fluid(maxWidth: 600, maxHeight: 600, quality: 100) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                        fixed(width: 5) {
                            ...GatsbyContentfulFixed
                        }
                        file {
                            contentType
                            url
                        }
                        sizes {
                            aspectRatio
                        }
                    }
                    category {
                        id
                        title
                    }
                    videoThumbnail {
                        file{
                            url
                            contentType
                        }
                    }
                }
            }
        }
    }
`

Project.defaultProps = {
    title: ''
}

export default Project
